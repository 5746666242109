var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{staticClass:"p-2",attrs:{"variant":"light","show":_vm.loading,"spinner-variant":"primary","blur":"0","opacity":".5","rounded":"sm"}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-observer',{ref:"formRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Nama","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Nama","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"disabled":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Training Center","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Training Center","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","reduce":function (option) { return option.id; },"options":_vm.trainingCenterItems,"state":errors.length > 0 ? false:null,"filterable":false},on:{"search":_vm.onSearchTrainingCenter},model:{value:(_vm.trainingCenterId),callback:function ($$v) {_vm.trainingCenterId=$$v},expression:"trainingCenterId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Batch","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Batch"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":"","state":errors.length > 0 ? false:null},model:{value:(_vm.batch),callback:function ($$v) {_vm.batch=$$v},expression:"batch"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Kuota","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Kuota","rules":"required|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false:null},model:{value:(_vm.quota),callback:function ($$v) {_vm.quota=$$v},expression:"quota"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('hr')],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Skill","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Skill","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","reduce":function (option) { return option.id; },"options":_vm.skillItems,"state":errors.length > 0 ? false:null,"placeholder":"Ketik untuk mencari..."},model:{value:(_vm.skillId),callback:function ($$v) {_vm.skillId=$$v},expression:"skillId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Kurikulum","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Kurikulum","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","reduce":function (option) { return option.id; },"options":_vm.curriculumItems,"state":errors.length > 0 ? false:null,"filterable":false,"placeholder":"Ketik untuk mencari...","disabled":!_vm.skillId},on:{"search":_vm.onSearchCurriculum},model:{value:(_vm.curriculumId),callback:function ($$v) {_vm.curriculumId=$$v},expression:"curriculumId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Posisi","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Posisi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"position_name","reduce":function (option) { return option.id; },"options":_vm.positionItems,"state":errors.length > 0 ? false:null,"placeholder":"Ketik untuk mencari..."},model:{value:(_vm.positionId),callback:function ($$v) {_vm.positionId=$$v},expression:"positionId"}},[(_vm.hasMorePosition)?_c('li',{staticClass:"vs__dropdown-option vs__dropdown-option--disabled",attrs:{"slot":"list-footer"},slot:"list-footer"},[_c('feather-icon',{attrs:{"icon":"MoreHorizontalIcon","size":"16"}})],1):_vm._e()]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('hr')],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Level","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Level","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"mt-50",attrs:{"options":_vm.levelOptions},model:{value:(_vm.level),callback:function ($$v) {_vm.level=$$v},expression:"level"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Instruktur","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Instruktur","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","reduce":function (option) { return option.id; },"options":_vm.instructorItems,"state":errors.length > 0 ? false:null,"filterable":false},on:{"search":_vm.onSearchInstructor},model:{value:(_vm.instructorId),callback:function ($$v) {_vm.instructorId=$$v},expression:"instructorId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Tanggal","label-cols-md":"4"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{ mode: 'range', altInput: true, altFormat: 'j/n/Y', dateFormat: 'Y-m-d',}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.submitErrors.start_date || _vm.submitErrors.end_date))])],1)],1),_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('b-button',{staticClass:"mr-50",attrs:{"variant":_vm.editMode ? 'warning' : 'primary',"type":"submit","disabled":_vm.loadingSubmit},on:{"click":function($event){$event.preventDefault();return _vm.submit($event)}}},[(_vm.loadingSubmit)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Submit ")],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }